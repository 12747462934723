export default {
  apiBasePath: "https://testapp.probilets.com/",
  // apiBasePath: "http://localhost:3000/",
  apiBasePathLocal: "http://localhost:3001/",
  apiKey: "d0d83e0b09ddecb21fecaf6821cf3d30",
  shopId: "565668",
  redirect: "https://probilets.com/confirm",
  webpay: "https://securesandbox.webpay.by",
  paymentTest: 1,
  storeId: "864939077",
  apiNbrb: "https://api.nbrb.by/exrates/rates?periodicity=0",
  apiIpWhoIs: "https://ipwhois.pro",
  tokenIpWhoIs: "nyUTq6VxJoAFnFqF",
  byDomain: "by.test.probilets.com",
  ruDomain: "ru.test.probilets.com",
  yandexAccount: [52030378],
  yandexMetric: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    ecommerce: "dataLayer"
  },
  strapiCmsHost: "https://cms.probilets.com",
  strapiApiKey:
    "85a1cb8625d98da4de7a1d25440f6033a70fa1f536d4ed2d70246e3a8efdf808787e3b83b05b13a8102a71404d4937798fb358656b9708c3d1a4279bed98fae9fa837173f51da5b9ba7ccf9e215a4bf81db0ff4197d8f1e9e208d3def1e02e8057f3d4b9b1e299d97c9cfa6af87eedbf6e4b3698801529161c8d19b1282c6b8b",
  yooKassaApi: "api.yookassa.ru/v3/payments",
  yooKassaApiKey: "test_26oswJtUKxgfcB4jhpfeKh_IpPMzObVY1XjFBVC8CxE",
  yooKassaApiID: "620420"

  // webpay: 'https://payment.webpay.by',
  // paymentTest: 0,
  // storeId: '354925258',
};
